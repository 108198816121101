import { Col, Row } from "antd"
import React from "react"
import ContactUs from "../../components/contactus"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import BlanketMarketingIcon from "../../images/heating-oil-blanket-marketing-icon.svg"
import BleedingCustomerBoilerIcon from "../../images/heating-oil-bleeding-customer-boilers-icon.svg"
import FuelTruckIcon from "../../images/heating-oil-fuel-truck-icon.svg"
import GlobeIcon from "../../images/heating-oil-globe-icon.svg"
import InefficientDeliveryIcon from "../../images/heating-oil-inefficient-delivery-icon.svg"
import PlanningIcon from "../../images/heating-oil-planning-icon.svg"
import ReduceCostIcon from "../../images/heating-oil-reduce-cost-icon.svg"
import SavingsIcon from "../../images/heating-oil-savings-icon.svg"
import SavingsIconWhite from "../../images/savings-icon-white.svg"
import IllustrationTankImage from "../../images/oil-distribution-tank-illustration-icon.svg"
import IllustrationTankerImage from "../../images/oil-distribution-tanker-illustration-icon.svg"
import IllustrationDepotImage from "../../images/oil-distribution-depot-illustration-icon.svg"
import IllustrationCustomerImage from "../../images/oil-distribution-customer-illustration-icon.svg"
import SupportIcon from "../../images/heating-oil-support-icon.svg"
import PathA from "../../images/path-1.svg"
import PathB from "../../images/path-2.svg"
import PathC from "../../images/path-3.svg"
import "../../styles/oil-distribution.sass"
import useBreakpoint from "../../utils/useBreakpoint"
import { useStaticQuery, graphql } from "gatsby"
import ProactiveSectionBuildingImage from "../../images/proactive-section-building.svg"
import ProactiveSectionFactoryImage from "../../images/proactive-section-factory.svg"
import ProactiveSectionFuelImage from "../../images/proactive-section-fuel.svg"
import ProactiveSectionHouseImage from "../../images/proactive-section-house.svg"
import ProactiveSectionPlaneImage from "../../images/proactive-section-plane.svg"
import ProactiveSectionPumpImage from "../../images/proactive-section-pump.svg"
import ProactiveSectionShipImage from "../../images/proactive-section-ship.svg"
import ProactiveSectionTractorImage from "../../images/proactive-section-tractor.svg"
import ProactiveSectionTruckImage from "../../images/proactive-section-truck.svg"

const IndexPage = () => {
  const breakPoint = useBreakpoint()
  const isSmallScreen = breakPoint.breakpoint === "sm" || breakPoint.breakpoint === "xs"
  const data = useStaticQuery(graphql`
    query {
      oilDistributionHeroImage: file(
        relativePath: { eq: "oil-distribution-vehicle.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoImage: file(relativePath: { eq: "magnus-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroSectionClasses = React.useMemo(() => {
    const baseClasses = ["hero-section"]
    if (isSmallScreen) {
      baseClasses.push("mobile-hero-section")
    }
    return baseClasses.join(' ')
  }, [isSmallScreen])
  return (
    <Layout>
      <SEO title="Industries - Heating Oil" />
      <main className="heating-oil">
          <section className={heroSectionClasses}>
            <Img
              // imgStyle={styles.analyticsDashboardPreviewLeftStyles}
              style={{ position: "initial" }}
              fluid={data.oilDistributionHeroImage.childImageSharp.fluid}
              alt="magnus-analytics-dashboard-preview-1"
            />
            <div className="hero-section-info">
              <div>
                <h1 className="hero-section-info-header">Oil Distribution</h1>
                <p className="hero-section-info-text">
                  We can help you to run your fuel oil business as efficiently
                  as possible.
                </p>
              </div>
            </div>
          </section>
        <section className="issue-highlights">
            <p className="issue-highlights-text">
              The lack of visibility on tank levels is a source of multiple pain
              points!!! This lack of knowledge impacts operating margins, and
              makes forecasting demand difficult at best. It can result in
              higher costs for both you and your customers and contributes to
              higher churn.
            </p>
            <Row
              className="issue-highlights-row"
              gutter={[52, 52]}
              justify="space-between"
              align="items-center"
            >
              <Col span={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                <div className="issue-highlights-item">
                  <div className="issue-highlights-item-icon">
                    <img
                      src={InefficientDeliveryIcon}
                      alt="heating-oil-inefficient-delivery"
                    />
                  </div>
                  <h1 className="issue-highlights-item-header">
                    Inefficient Delivery Process
                  </h1>
                  <p className="issue-highlights-item-text">
                    Visiting the same locations multiple times a week
                  </p>
                </div>
              </Col>
              <Col span={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                <div className="issue-highlights-item">
                  <div className="issue-highlights-item-icon">
                    <img
                      src={BleedingCustomerBoilerIcon}
                      alt="heating-oil-bleeding-customer-boiler"
                    />
                  </div>
                  <h1 className="issue-highlights-item-header">
                    Bleeding Customer Boilers
                  </h1>
                  <p className="issue-highlights-item-text">
                    Inefficient use of your driver’s time.
                  </p>
                </div>
              </Col>
              <Col span={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                <div className="issue-highlights-item">
                  <div className="issue-highlights-item-icon">
                    <img
                      src={BlanketMarketingIcon}
                      alt="heating-oil-blanket-marketing"
                    />
                  </div>
                  <h1 className="issue-highlights-item-header">
                    Blanket Marketing
                  </h1>
                  <p className="issue-highlights-item-text">
                    Targeting the wrong customers at the wrong time.
                  </p>
                </div>
              </Col>
            </Row>
        </section>
          <section className="platform-title">
            <Row align="middle" justify="center">
              <Col xs={2} sm={2} md={1} lg={1} xl={1}>
                <div className="logo-image-container">
                  <Img
                    style={{ position: "initial" }}
                    fluid={data.logoImage.childImageSharp.fluid}
                    alt="magnus-analytics-dashboard-preview-1"
                  />
                </div>
              </Col>
              <Col>
                <p className="logo-text">magnus.&nbsp;platform</p>
              </Col>
            </Row>
            <h1 className="logo-catchphrase">
              SEAMLESS INTEGRATION FOR OPTIMAL EFFICIENCY
            </h1>
          </section>
          <section className="illustrations">
              <div className="illustrations-item">
                <div className="illustrations-item-content">
                  <div className="illustrations-item-content-icon">
                    <img src={IllustrationTankImage} alt="illustrations-tank" />
                  </div>
                  <div className="illustrations-item-content-info">
                    <div className="illustrations-item-content-info-header">
                      Tank
                    </div>
                    <ul className="illustrations-item-content-info-text">
                      <li>Accurate real-time visibility on oil levels</li>
                      <li>
                        Quick & Easy install, no drilling holes or alterations
                        to tank
                      </li>
                      <li>
                        Monitor suitable for residential and commercial tanks
                      </li>
                    </ul>
                  </div>
                  {!isSmallScreen ? <img src={PathA} alt="path-1" /> : null}
                </div>
              </div>
              <div className="illustrations-item">
                <div className="illustrations-item-content">
                  {!isSmallScreen ? <img src={PathB} alt="path-2" /> : null}
                  <div className="illustrations-item-content-icon">
                    <img src={IllustrationTankerImage} alt="illustrations-tanker" />
                  </div>
                  <div className="illustrations-item-content-info">
                    <div className="illustrations-item-content-info-header">
                      Tanker
                    </div>
                    <ul className="illustrations-item-content-info-text">
                      <li>Reduction in your carbon footprint.</li>
                      <li>Maximise route optimisation</li>
                      <li>Reductions in admin and overtime costs</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="illustrations-item">
                <div className="illustrations-item-content">
                  <div className="illustrations-item-content-icon">
                    <img src={IllustrationDepotImage} alt="illustrations-depot" />
                  </div>
                  <div className="illustrations-item-content-info">
                    <div className="illustrations-item-content-info-header">
                      Depot
                    </div>
                    <ul className="illustrations-item-content-info-text">
                      <li>
                        Improved stock control - ability to predict, plan and
                        manage
                      </li>
                      <li>Reduction in marketing costs - targeted campaigns</li>
                      <li>
                        Integration into third party back office systems
                        available
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="illustrations-item">
                <div className="illustrations-item-content">
                  {!isSmallScreen ? <img src={PathC} alt="path-3" /> : null}
                  <div className="illustrations-item-content-icon">
                    <img src={IllustrationCustomerImage} alt="illustrations-customer" />
                  </div>
                  <div className="illustrations-item-content-info">
                    <div className="illustrations-item-content-info-header">
                      Customer
                    </div>
                    <ul className="illustrations-item-content-info-text">
                      <li>Customer retention</li>
                      <li>New customer acquisition</li>
                      <li>
                        Conversion to payment plans increasing cash flow
                        throughout the year
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </section>
        <section className="proactive-clients-section">
            <h1>
              Magnus allows you to be <span>PROACTIVE</span> with all your
              customers.
            </h1>
            <Row gutter={[70, 40]} align="middle" justify="center">
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionTractorImage}
                    alt="proactive-section-tractor"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionTruckImage}
                    alt="proactive-section-truck"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionHouseImage}
                    alt="proactive-section-house"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionFactoryImage}
                    alt="proactive-section-factory"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionFuelImage}
                    alt="proactive-section-oil"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionShipImage}
                    alt="proactive-section-ship"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionPlaneImage}
                    alt="proactive-section-plane"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionBuildingImage}
                    alt="proactive-section-commercial-building"
                  />
                </div>
              </Col>
              <Col>
                <div className="proactive-clients-section-item">
                  <img
                    src={ProactiveSectionPumpImage}
                    alt="proactive-section-pump-image"
                  />
                </div>
              </Col>
            </Row>
        </section>
        <section className="benefits-info">
            <ul className="benefits-info-list">
              <li className="benefits-info-list-item">
                <div className="benefits-info-list-item-icon">
                  <img
                    src={SupportIcon}
                    alt="extra-benefits-info-support-icon"
                  />
                </div>
                <div className="benefits-info-list-item-content">
                  <h1 className="benefits-info-list-item-content-header">
                    INCREASED CUSTOMER SERVICE LEVELS
                  </h1>
                  <p className="benefits-info-list-item-content-text">
                    Monitoring with Magnus allows oil distribution to offer
                    higher levels of customer service which helps keep the
                    customers you have for longer and acts as a great incentive
                    to acquire new customers. In time, this service will help
                    you increase the number of customers of a payment plan model
                    resulting in better stock control and a cash flow that is
                    spread more evenly throughout the year, not just in peaks
                    and troughs.
                  </p>
                </div>
              </li>
              <li className="benefits-info-list-item">
                <div className="benefits-info-list-item-icon">
                  <img
                    src={PlanningIcon}
                    alt="extra-benefits-info-planning-icon"
                  />
                </div>
                <div className="benefits-info-list-item-content">
                  <h1 className="benefits-info-list-item-content-header">
                    FEWER DELIVERY TRIPS
                  </h1>
                  <p className="benefits-info-list-item-content-text">
                    Once you know the level in a customer’s tank, you then know
                    the number of trips required to keep them topped up over the
                    year. If an average customer gets a top-up 3 times a year
                    this could now drop to 2 without concern for example. For 1k
                    monitors, this will save 1000 deliveries, plus the costs
                    involved, per year without a drop in revenue.
                  </p>
                </div>
              </li>
              <li className="benefits-info-list-item">
                <div className="benefits-info-list-item-icon">
                  <img
                    src={ReduceCostIcon}
                    alt="extra-benefits-info-reduce-cost-icon"
                  />
                </div>
                <div className="benefits-info-list-item-content">
                  <h1 className="benefits-info-list-item-content-header">
                    REDUCE MARKETING COSTS
                  </h1>
                  <p className="benefits-info-list-item-content-text">
                    Whether it be a cold snap, snow on the ground or an upcoming
                    holiday period like Christmas, people tend to panic order
                    which clogs phone lines, ties up drivers on unnecessary
                    deliveries, stretches delivery times increasing potential
                    customer churn contributing to lowering margin, this will be
                    a thing of the past with Magnus. As the number of your
                    monitored customers grows, the number of unpredictable and
                    emergency callouts reduces. This in turn means that the
                    majority of the orders and workload can be managed during
                    normal office hours reducing the need for overtime and the
                    associated costs.
                  </p>
                </div>
              </li>
              <li className="benefits-info-list-item">
                <div className="benefits-info-list-item-icon">
                  <img
                    src={SavingsIconWhite}
                    alt="extra-benefits-info-savings-icon"
                  />
                </div>
                <div className="benefits-info-list-item-content">
                  <h1 className="benefits-info-list-item-content-header">
                    POTENTIAL SAVINGS
                  </h1>
                  <p className="benefits-info-list-item-content-text">
                    There are potential savings in multiple areas, be it stock
                    forecasting, drawing stock from the terminal, costs of
                    storage in depots, costs of moving stock between depots all
                    the way down to managing the volume of stock each tanker has
                    to carry. it can minimise return trips to the depot for
                    filling up or allow you to service more customers with the
                    same tanker capacity. Either way it can deliver savings.
                  </p>
                </div>
              </li>
            </ul>
        </section>
      </main>
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
